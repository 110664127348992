li {
    text-align : left;
    text-indent : -1.5em;
    padding-left : 1.5em;
    margin : 0px 10px;
    font-family: 'Roboto', sans-serif;
} star

 /* .line {
    border-bottom: solid 2px lightgray;
    width: auto;
    margin-top: 0px;
    margin-bottom: 20px;
}

.line1 {
    border-bottom: solid 2px rgb(143,169,169);
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
} */
.Card{
    border-radius :35px;
}
.jumbotron {
    /* text-align: center; */
    padding-top: 10rem!important;
    text-shadow: 2px 2px #000;
    color : white;
    /* height: 850px; */
    font-size : 35px;
    font-family: 'Roboto', sans-serif;;   
}

.jumbotron h1 {
    font-size: 80px;
    letter-spacing : 3px;
    font-weight: 500;
    text-shadow: 2px 2px #000;
}

.con1 {
    color:white;
    font-size: 15px;
    font-weight: normal;
    vertical-align: middle;
}

.jumbotron p {
    font-size: 23px;
    line-height: 0.8;
}

.bg-cover {
    background-image: url('/images/bgimg.jpg');
    background-size : cover;
}
.bg-cover1 {
    background-image: url('/images/about.jpg');
    background-size : cover;
}

.bg-cover2 {
    background-image: url('/images/map-background.png');
    background-size : cover;
    height: 830px;
    margin: auto;
    position: relative;
    padding: 20px;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
}

.bg-tech {
    background-image: url('/images/about.jpg');
    /* max-width: 100%; */
    background-size : cover;
}

.container h2{
    font-size:25px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0px;
    text-align: center;
}

.info{
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-Size: 22px;
    background-color: /* rgb(255, 174, 0);*/ #ff9100c7;
    color: rgb(255, 255, 255);
    padding: 5px 30px;
    display: table;
    border-radius:22px;
    margin-top: 20px;
    margin-bottom: 30px;
    /*border-radius: 30px;*/
}


.container h4 {
    background-color:#e2e9ed;
    margin: auto;
    font-family:Arial;
	line-height:1px;
	border-radius:25px;
	text-align:center;
    vertical-align:middle;
    font-size:16px;
    color:#165172;
    font-family: 'Roboto', sans-serif;
    width:256px;
    height: 0px;
    padding:5px;
}

p.techbox {
    background-color: #335f72;
    margin: auto;
    font-family:Arial;
	line-height:1px;
	border-radius:25px;
	text-align:center;
    vertical-align:middle;
    font-size:16px;
    color:white;
    width:350px;
    height: 0px;
    padding:20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.secu {
    font-weight: 600;
    font-size: 20px;
    color: gray;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
}

.line {
    border-bottom: solid 2px rgb(231, 231, 231);
    width: auto;
    margin-bottom: 40px;
}

.line1 {
    border-bottom: solid 1px white ;
    width: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.line2 {
    border-bottom: solid 2px rgb(207, 207, 207);
    width: auto;
    margin-bottom: 40px;
    margin-top: 60px;
    
}

.line3 {
    border-bottom: solid 20px rgb(207, 207, 207);
    width: auto;
}

.noline {
    border: white;
}

p.sp {
    font-size: 15px;
    color: #999999;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

img.box {
    border: 2px #979797 solid;
    border-radius: 100px;
    width: 200px;
    height: 200px;
    margin: auto;
    }

img.sol1 {
    margin-top: 50px;
    margin-bottom: 65px;
}

img.sol2 {
    margin-top: 15px;
    margin-bottom: 10px;
}

.mapB {
    border:1px;
    width: auto;
    padding: 20px 0px;
    color : white;
}

p.vision {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
    color: gray;
}

p.slash {
    font-weight: bold ;
    color: #a3a3a3;
    text-align: center;
}

li.sol {
    font-size: 16.5px;
    font-family: 'Roboto', sans-serif;
}

.home .header {  /* background-image: ''url(/image-header/dogs.jpg)''; */
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white ;
  padding: 100px 25px;  
  text-align: center;
}
.home .title {font-weight: bold; font-size: 5rem}

.slideanim {visibility:hidden;}
.slide {
    animation-name: slide;
    -webkit-animation-name: slide;	
    animation-duration: 1s;	
    -webkit-animation-duration: 1s;
    visibility: visible;			
}

@keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    } 
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }	
}
@-webkit-keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    } 
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@media screen and (max-width: 768px) {
    .col-sm-4 {
        text-align: center;
        margin: 25px 0;
    }
    .btn-lg {
        width: 100%;
        margin-bottom: 35px;
    }
}
@media screen and (max-width: 480px) {
    .logo {
        font-size: 150px;
    }
}