@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;300;500;700&family=Roboto:ital,wght@0,300;0,400;0,900;1,300;1,500&display=swap);
li {
    text-align : left;
    text-indent : -1.5em;
    padding-left : 1.5em;
    margin : 0px 10px;
    font-family: 'Roboto', sans-serif;
} star

 /* .line {
    border-bottom: solid 2px lightgray;
    width: auto;
    margin-top: 0px;
    margin-bottom: 20px;
}

.line1 {
    border-bottom: solid 2px rgb(143,169,169);
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
} */
.Card{
    border-radius :35px;
}
.jumbotron {
    /* text-align: center; */
    padding-top: 10rem!important;
    text-shadow: 2px 2px #000;
    color : white;
    /* height: 850px; */
    font-size : 35px;
    font-family: 'Roboto', sans-serif;;   
}

.jumbotron h1 {
    font-size: 80px;
    letter-spacing : 3px;
    font-weight: 500;
    text-shadow: 2px 2px #000;
}

.con1 {
    color:white;
    font-size: 15px;
    font-weight: normal;
    vertical-align: middle;
}

.jumbotron p {
    font-size: 23px;
    line-height: 0.8;
}

.bg-cover {
    background-image: url('/images/bgimg.jpg');
    background-size : cover;
}
.bg-cover1 {
    background-image: url('/images/about.jpg');
    background-size : cover;
}

.bg-cover2 {
    background-image: url('/images/map-background.png');
    background-size : cover;
    height: 830px;
    margin: auto;
    position: relative;
    padding: 20px;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
}

.bg-tech {
    background-image: url('/images/about.jpg');
    /* max-width: 100%; */
    background-size : cover;
}

.container h2{
    font-size:25px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0px;
    text-align: center;
}

.info{
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-Size: 22px;
    background-color: /* rgb(255, 174, 0);*/ #ff9100c7;
    color: rgb(255, 255, 255);
    padding: 5px 30px;
    display: table;
    border-radius:22px;
    margin-top: 20px;
    margin-bottom: 30px;
    /*border-radius: 30px;*/
}


.container h4 {
    background-color:#e2e9ed;
    margin: auto;
    font-family:Arial;
	line-height:1px;
	border-radius:25px;
	text-align:center;
    vertical-align:middle;
    font-size:16px;
    color:#165172;
    font-family: 'Roboto', sans-serif;
    width:256px;
    height: 0px;
    padding:5px;
}

p.techbox {
    background-color: #335f72;
    margin: auto;
    font-family:Arial;
	line-height:1px;
	border-radius:25px;
	text-align:center;
    vertical-align:middle;
    font-size:16px;
    color:white;
    width:350px;
    height: 0px;
    padding:20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.secu {
    font-weight: 600;
    font-size: 20px;
    color: gray;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
}

.line {
    border-bottom: solid 2px rgb(231, 231, 231);
    width: auto;
    margin-bottom: 40px;
}

.line1 {
    border-bottom: solid 1px white ;
    width: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.line2 {
    border-bottom: solid 2px rgb(207, 207, 207);
    width: auto;
    margin-bottom: 40px;
    margin-top: 60px;
    
}

.line3 {
    border-bottom: solid 20px rgb(207, 207, 207);
    width: auto;
}

.noline {
    border: white;
}

p.sp {
    font-size: 15px;
    color: #999999;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

img.box {
    border: 2px #979797 solid;
    border-radius: 100px;
    width: 200px;
    height: 200px;
    margin: auto;
    }

img.sol1 {
    margin-top: 50px;
    margin-bottom: 65px;
}

img.sol2 {
    margin-top: 15px;
    margin-bottom: 10px;
}

.mapB {
    border:1px;
    width: auto;
    padding: 20px 0px;
    color : white;
}

p.vision {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
    color: gray;
}

p.slash {
    font-weight: bold ;
    color: #a3a3a3;
    text-align: center;
}

li.sol {
    font-size: 16.5px;
    font-family: 'Roboto', sans-serif;
}

.home .header {  /* background-image: ''url(/image-header/dogs.jpg)''; */
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white ;
  padding: 100px 25px;  
  text-align: center;
}
.home .title {font-weight: bold; font-size: 5rem}

.slideanim {visibility:hidden;}
.slide {
    animation-name: slide;
    -webkit-animation-name: slide;	
    animation-duration: 1s;	
    -webkit-animation-duration: 1s;
    visibility: visible;			
}

@keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    } 
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }	
}
@-webkit-keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    } 
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}

@media screen and (max-width: 768px) {
    .col-sm-4 {
        text-align: center;
        margin: 25px 0;
    }
    .btn-lg {
        width: 100%;
        margin-bottom: 35px;
    }
}
@media screen and (max-width: 480px) {
    .logo {
        font-size: 150px;
    }
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}
/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */


 
 html{
   scroll-behavior : smooth;
 }

 .navbar {
  padding : 0px !important;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}

.navbar-brand {
  font-weight: bold;
  padding-left: 15px;
}

/* .nav-bg-blue {
  background-color: steelblue !important; 
}
 */

.nav-bg-white {
  background-color: white !important; 
}

/* background-color: white !important;  */
.navbar-brand a {
  color: white;
  text-decoration: none;
}
.navbar-brand a:hover {
  color: aliceblue;  
  text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */


/* .navbar-collapse a {
  color : black !important;
  padding : 20px !important;
  font-weight : 400!important;
  text-decoration : none!important;
}
.navbar-collapse a:hover, .navbar-collapse a:active {
  background-clip : padding-box!important; 
  border-bottom : 4.5px solid orange!important;
  color : white !important;
  background-color: rgb(15,43,44) !important;
  text-decoration : none!important;
} */


.nav-link {
  padding: 20px !important;
  font-weight: 400;
  color: black !important;
  border-bottom: 4.5px solid transparent;
}

.nav-link:hover, .nav-link.active {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 4.5px solid orange ;
    transition: all 0.3s ease-in-out;
} 






.dropdown-item {
  color: gray !important;
}

.dropdown-item:hover, .dropdown-item.active {
  color: skyblue !important;

  background-color: white !important;

  /* color: white;
  background-color: orange !important; */

}

.footer {
  width: 100%;
  padding: 45px 20%;
  margin: 10px 0 0;
  /* background-color: #333; */
  background-color: #2d2d30;
  ;
  color: #ddd;  
  font-family: 'Roboto';
}

@media screen and (min-width: 960px) {  
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}

/**
  define Global style for 
    Fonts: Roboto, Gothic
    font-size 1.2rem, 1.5rem 1.6rem
    weight 
    background-color: 
      navbar:       steelblue   #4682b4 rgb(70,130,180)
      footer:                   #315a7d rgb(49,90,125)
      section bar:  skyblue     #87ceeb rgb(135,206,235)
    text color:                 #444444
 */

 body {
   margin: 0;
   padding: 0;
   /* font-family: "Open Sans", sans-serif;
   font-family: Lato, Verdana, sans-serif; */
   font-family: 'Roboto', sans-serif;
   font-family: 'Gothic A1', sans-serif;
   font-size: 1.1rem;
   font-weight: 300;
   color: #333;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
 }
 
 select.form-control,
 textarea.form-control,
 input[type=file] {
  width : 25%;
  margin : 0 auto;
 }
 /* input[type=email], select{
  width : 25%;
  margin : 0 auto;
   
 }
 input[type =password],select{
   width : 25%;
   margin : 0 auto;
   
 }

 button[type=submit]{
   width : 25%;
   margin : 0 auto;
  
 } */

 
 h1 {padding: 30px 0; text-align: center;}
 h2 {padding-top: 20px; padding-bottom: 20px;}
 
 h1, h2, h3, h4 {font-weight: 500;} 
 h5 {font-weight: 300}
 
 h1 {font-size: 3.2rem;}
 h2 {font-size: 2.4rem;}
 h3 {font-size: 2.0rem; text-align: center;}
 h4 {font-size: 1.6rem;}
 h5 {font-size: 1.4rem;}

.page .header {
   margin-top: 10px;
   /* background-image: url(/image-header/dogs.jpg); */
   background-position: 0% 50%;
   background-size: cover;
   background-repeat: no-repeat;
   color: white ;
   padding: 30px 25px ;
   text-align: center
}  

.page .content {margin-top: 10px}

.card-title { font-weight: 600}

.ver-mid-wrap {
  display:flex;
  justify-content:center;
  align-items:center;
}

.page section {margin-top: 50px}

section .title {
  text-align: center;
  /* color: blue; */
  background-color: deepskyblue;
  color: white;
  border-radius: 10px;
  padding: 15px 0 10px;
  margin-top: 50px
}

.faq-item {margin-top: 3rem}
.faq-q, 
.faq-a 
{
  padding: 0 2rem;
  text-indent: -2rem;  
}

p.faq-q::first-letter,
p.faq-a::first-letter
{
  font-size: 2rem;
  color: #8A2BE2;
}

.faq-item > ul {
  margin: 1rem 2rem
}
table ul {margin: 0}

