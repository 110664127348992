/**
 * define App.js style for 
 *  Navigation tab,
 *  Footer
 * 
 */


 
 html{
   scroll-behavior : smooth;
 }

 .navbar {
  padding : 0px !important;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}

.navbar-brand {
  font-weight: bold;
  padding-left: 15px;
}

/* .nav-bg-blue {
  background-color: steelblue !important; 
}
 */

.nav-bg-white {
  background-color: white !important; 
}

/* background-color: white !important;  */
.navbar-brand a {
  color: white;
  text-decoration: none;
}
.navbar-brand a:hover {
  color: aliceblue;  
  text-decoration: none;
}

/*
.navbar-nav a, .navbar-text {
  padding: 20px !important;
  color: white !important;
}

.navbar-nav a:hover {
  color: rgb(241, 184, 163) !important;  
}

.dropdown-menu a {  
  padding: 5px 10px !important;
  color: steelblue !important
} */


/* .navbar-collapse a {
  color : black !important;
  padding : 20px !important;
  font-weight : 400!important;
  text-decoration : none!important;
}
.navbar-collapse a:hover, .navbar-collapse a:active {
  background-clip : padding-box!important; 
  border-bottom : 4.5px solid orange!important;
  color : white !important;
  background-color: rgb(15,43,44) !important;
  text-decoration : none!important;
} */


.nav-link {
  padding: 20px !important;
  font-weight: 400;
  color: black !important;
  border-bottom: 4.5px solid transparent;
}

.nav-link:hover, .nav-link.active {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 4.5px solid orange ;
    transition: all 0.3s ease-in-out;
} 






.dropdown-item {
  color: gray !important;
}

.dropdown-item:hover, .dropdown-item.active {
  color: skyblue !important;

  background-color: white !important;

  /* color: white;
  background-color: orange !important; */

}

.footer {
  width: 100%;
  padding: 45px 20%;
  margin: 10px 0 0;
  /* background-color: #333; */
  background-color: #2d2d30;
  ;
  color: #ddd;  
  font-family: 'Roboto';
}

@media screen and (min-width: 960px) {  
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}
